/* global self */
/* global DEV_MODE */
/* global VERBOSE */
'use strict'

import '../helpers/polyfills'
import parseUrl from 'url-parse'
import { Trace, Breadcrumb, AppError } from '../logger'
import FesDataFetcher from '../inverted-dependencies/FesDataFetcher'
import WixDataFetcher from '../inverted-dependencies/WixDataFetcher'
import WarmupCache from '../inverted-dependencies/WarmupCache'
import StaticCache from '../inverted-dependencies/StaticCache'
import Features from '../inverted-dependencies/Features'
import Logger from '../inverted-dependencies/Logger'
import DataBinding from './DataBinding'
import { createListenersByEvent } from '../inverted-dependencies/createListenersByEvent'
import { WixDataSchemas } from '@wix/wix-data-schemas-client'
import Platform from '../inverted-dependencies/Platform'

export default class App {
  constructor({
    //TODO: all this crap is in constructor, because it can be passed from IT tests. AAAAAAA!!!!!
    //TODO: kurva!!! should be removed after crappy it tests for internal business logic will be changed to units.
    //TODO: And WixDataFetcher integration with wix data and schemas should be tested separately
    wixDataSchemasForItTests,
    getElementorySupport = () => global.elementorySupport,
  } = {}) {
    this.#wixDataSchemasForItTests = wixDataSchemasForItTests
    this.#getElementorySupport = getElementorySupport

    return {
      initAppForPage: this.initAppForPage,
      createControllers: this.createControllers,
    }
  }

  initAppForPage = (
    { routerReturnedData, ...platformSettings },
    platformUtils,
    wixSdk,
    //TODO: bolt support, thunderbolt provides all this info in the second arg
    {
      bi = {},
      monitoring: { createMonitor },
      fedOpsLoggerFactory,
      biLoggerFactory,
      essentials: { httpClient, experiments },
    } = {},
  ) => {
    try {
      const platform = new Platform({
        platformUtils,
        wixSdk,
        bi,
        devMode: DEV_MODE,
        verbose: Boolean(VERBOSE),
      })
      const { settings } = platform
      const { instance, gridAppId } = extractInstanceAndGridAppId(
        platformSettings,
        this.#getElementorySupport(),
      )
      const {
        data: wixData,
        window: { warmupData },
        location: { baseUrl, protocol },
      } = wixSdk

      this.#logger = new Logger({
        fedops: {
          factory: fedOpsLoggerFactory,
          hooks: {
            start: ({ name }) =>
              this.#logger.log(
                new Breadcrumb({
                  category: 'interaction start',
                  message: `interaction ${name} started`,
                }),
              ),
            end: ({ name, duration }) =>
              this.#logger.log(
                new Breadcrumb({
                  category: 'interaction end',
                  message: `interaction ${name} ended after ${duration} ms`,
                }),
              ),
          },
        },
        bi: { factory: biLoggerFactory },
        monitor: { factory: createMonitor },
        verbose: {
          factory: () => ({
            // eslint-disable-next-line no-console
            log: (...args) => console.verbose(...args),
          }),
        },
        console: {
          factory: () => console,
        },
        settings,
        global: self,
      })

      this.#logger.log(
        new Trace('databinding/initAppForPage', Trace.types.START),
      )

      const features = new Features({ experiments, settings })

      const dataFetcher = features.fes
        ? new FesDataFetcher({
            httpClient,
            getRequestParams: () => ({ instance, gridAppId }),
          })
        : new WixDataFetcher({
            wixData: wixData || self.require('wix-data').default,
            wixDataSchemas:
              this.#wixDataSchemasForItTests ||
              new WixDataSchemas(httpClient, instance, gridAppId, {
                baseUrl: settings.env.editor
                  ? undefined
                  : `${protocol}://${
                      parseUrl(baseUrl).hostname
                    }/_api/cloud-data/v1/schemas`,
              }),
          })

      const warmupCache = new WarmupCache(warmupData)
      const staticCache = new StaticCache(routerReturnedData)

      const listenersByEvent = createListenersByEvent({
        httpClient,
        env: settings.env,
      })

      this.#dataBinding = new DataBinding({
        platform,
        dataFetcher,
        warmupCache,
        staticCache,
        features,
        listenersByEvent,
        logger: this.#logger,
        global: self,

        routerReturnedData,
      })

      this.#logger.log(new Trace('databinding/initAppForPage', Trace.types.END))

      return Promise.resolve()
    } catch (e) {
      this.#logger &&
        this.#logger.log(
          new AppError('App initialisation failed', { cause: e }),
        )
      return Promise.reject(e)
    }
  }

  createControllers = rawControllerConfigs => {
    return rawControllerConfigs.length
      ? this.#dataBinding.initializeDatasets({
          rawControllerConfigs,
        })
      : []
  }

  #dataBinding
  #logger

  #wixDataSchemasForItTests
  #getElementorySupport
}

const extractInstanceAndGridAppId = (
  { instance: appInstance, appData },
  elementorySupport,
) => {
  if (appInstance && appData) {
    return {
      instance: appInstance,
      gridAppId: appData.gridAppId,
    }
  }

  //TODO: bolt support, remove after it's death.
  const {
    query: { instance, gridAppId },
  } = parseUrl(`?${elementorySupport.queryParameters}`, true)

  return { instance, gridAppId }
}
