'use strict'

import { uniq, flow, isNull } from 'lodash-es'
import { Maybe } from '@wix/wix-code-adt'
import {
  UPLOAD_BUTTON_ROLE,
  SIGNATURE_INPUT_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { SCOPE_TYPES } from '@wix/dbsm-common/src/scopes/consts'
import {
  getFilter,
  getSort,
  shouldAllowWixDataAccess,
  selectCurrentRecord,
  selectCurrentRecordIndex,
  getDatasetStaticConfig,
  isDatasetConfigured,
  getCurrentPageSize,
} from './rootReducer'
import recordActions from '../records/actions'
import dynamicPagesActions from '../dynamic-pages/actions'
import configActions from '../dataset-config/actions'
import rootActions from './actions'
import configureDatasetStore from './configureStore'
import { performHandshake } from '../dependency-resolution/actions'
import datasetApiCreator from '../dataset-api/datasetApi'
import eventListenersCreator from '../dataset-events/eventListeners'
import syncComponentsWithState from '../side-effects/syncComponentsWithState'
import { getFieldTypeCreator } from '../data/utils'
import createConnectedComponentsStore from '../connected-components'
import {
  adapterApiCreator,
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts,
  initAdapters,
} from '../components'
import {
  createFilterResolver,
  createValueResolvers,
  hasDatabindingDependencies,
} from '../filter-resolvers'
import wixFormattingCreator from '@wix/wix-code-formatting'
import dependenciesManagerCreator from '../dependency-resolution/dependenciesManager'
import { isSameRecord, createRecordStoreInstance } from '../record-store'
import rootSubscriber from './rootSubscriber'
import dynamicPagesSubscriber from '../dynamic-pages/subscriber'
import createSiblingDynamicPageUrlGetter from '../dynamic-pages/siblingDynamicPageGetterFactory'
import fetchData from './dataFetcher'
import generateRecordFromDefaultComponentValues from '../helpers/generateRecordFromDefaultComponentValues'
import { getComponentsToUpdate } from '../helpers/livePreviewUtils'
import { Dispatcher, errorHandling } from '../helpers'
import appContext from '../viewer-app-module/DataBindingAppContext'
import { AppError, Trace, reportDatasetActiveOnPage } from '../logger'
import { VerboseMessage } from '../logger/events'

const onChangeHandler = (getState, dispatch, adapterApi) => {
  const { logger } = appContext
  const areArgumentsIllegal = (before, after) => isNull(before) && isNull(after)
  const recordWasAdded = (before, after) => isNull(before)
  const recordWasDeleted = (before, after) => isNull(after)
  const currentRecordWasChanged = (changedRecord, currentRecord) =>
    isSameRecord(changedRecord, currentRecord)

  return (before, after, componentIdToExclude) => {
    const argsAreIllegal = areArgumentsIllegal(before, after)
    if (argsAreIllegal) {
      logger.log(
        new AppError('onChangeHandler invoked with illegal arguments', {
          extra: { arguments: { before, after, componentIdToExclude } },
        }),
      )
      return
    }

    if (recordWasAdded(before, after)) {
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    const currentRecord = selectCurrentRecord(getState())

    if (recordWasDeleted(before, after)) {
      if (isSameRecord(before, currentRecord)) {
        dispatch(recordActions.refreshCurrentRecord()).catch(() => {})
      }
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    if (currentRecordWasChanged(before, currentRecord)) {
      const currentRecordIndex = selectCurrentRecordIndex(getState())

      dispatch(
        recordActions.setCurrentRecord(
          after,
          currentRecordIndex,
          componentIdToExclude,
        ),
      ).catch(() => {})
    }
  }
}

function waitForAllChildControllersToBeReady(controllerStore) {
  return Promise.all(
    controllerStore.getAll().map(
      scope =>
        new Promise(resolve => {
          scope.staticExports.onReady(resolve)
        }),
    ),
  )
}

const getFirstRecord = service =>
  service.getSeedRecords().matchWith({
    Empty: () => Maybe.Nothing(),
    Results: ({ items }) => Maybe.Just(items[0]),
  })

const createDataset =
  (controllerFactory, controllerStore) =>
  (
    isScoped,
    isFixedItem,
    {
      $w,
      controllerConfig,
      datasetType,
      connections,
      dataProvider,
      firePlatformEvent,
      dynamicPagesData,
      datasetId,
      fixedRecordId,
      handshakes = [],
      recordStoreService,
      parentId,
      updatedCompIds,
      markControllerAsRendered,
      markDatasetDataFetched,
      renderingRegularControllers,
      modeIsLivePreview,
      modeIsSSR,
      useLowerCaseDynamicPageUrl,
      schemasLoading,
      listenersByEvent,
    },
  ) => {
    const {
      logger,
      platform: {
        user,
        settings: {
          locale,
          env: { renderer },
        },
      },
    } = appContext

    const {
      findConnectedComponents,
      setConnectedComponents,
      resolveHandshakes,
      getConnectedComponents,
      getConnectedComponentIds,
    } = createConnectedComponentsStore()
    const unsubscribeHandlers = []

    const { store, subscribe, onIdle } = configureDatasetStore(
      logger,
      datasetId,
    )

    const eventListeners = eventListenersCreator(firePlatformEvent)

    const { fireEvent } = eventListeners
    unsubscribeHandlers.push(eventListeners.dispose)

    // Our system has two event listening system.
    // One is internal, meaning those events can be listened only by our own code
    // And the second one is external, meaning these events are listened by wix code, components, etc.
    // dispatcher - internal, eventListeners - external (legacy)
    // TODO: but before dispatcher was introduced, everything was in eventListeners, so it should be refactored
    const dispatcher = new Dispatcher({
      datasetId,
      getState: store.getState,
      getSchema: (name = datasetCollectionName) => dataProvider.getSchema(name),
    })

    const internalEventsUnsubscibers = dispatcher.subscribe(listenersByEvent)

    unsubscribeHandlers.push(...internalEventsUnsubscibers)

    store.dispatch(
      rootActions.init({
        controllerConfig,
        connections,
        isScoped,
        datasetType,
      }),
    )
    const {
      datasetIsVirtual,
      datasetIsReal,
      datasetIsDeferred,
      datasetIsWriteOnly,
      datasetCollectionName,
      dynamicPageNavComponentsShouldBeLinked,
    } = getDatasetStaticConfig(store.getState())

    const dependenciesManager = dependenciesManagerCreator()
    unsubscribeHandlers.push(dependenciesManager.unsubscribe)

    const filter = getFilter(store.getState())

    const getSchema = (schemaName = datasetCollectionName) => {
      return Maybe.fromNullable(dataProvider.getSchema(schemaName))
    }

    const getFieldType = fieldName => {
      const schema = getSchema(datasetCollectionName)
      const referencedCollectionsSchemas = dataProvider.getReferencedSchemas(
        datasetCollectionName,
      )
      return schema.chain(s =>
        Maybe.fromNullable(
          getFieldTypeCreator(s, referencedCollectionsSchemas)(fieldName),
        ),
      )
    }

    const valueResolvers = createValueResolvers(
      dependenciesManager.get(),
      getConnectedComponents,
      getFieldType,
    )
    const filterResolver = createFilterResolver(valueResolvers)

    const recordStore = createRecordStoreInstance({
      recordStoreService,
      getFilter: flow(_ => store.getState(), getFilter),
      getSort: flow(_ => store.getState(), getSort),
      getPageSize: flow(_ => store.getState(), getCurrentPageSize),
      shouldAllowWixDataAccess: flow(
        _ => store.getState(),
        shouldAllowWixDataAccess,
      ),
      datasetId,
      filterResolver,
      getSchema,
      fixedRecordId,
    })

    const siblingDynamicPageUrlGetter = dynamicPageNavComponentsShouldBeLinked
      ? createSiblingDynamicPageUrlGetter({
          dataProvider,
          dynamicPagesData,
          collectionName: datasetCollectionName,
          useLowerCaseDynamicPageUrl,
        })
      : null

    if (dynamicPageNavComponentsShouldBeLinked) {
      subscribe(dynamicPagesSubscriber(siblingDynamicPageUrlGetter))
      store.dispatch(dynamicPagesActions.initialize(connections))
    }

    const datasetApi = datasetApiCreator({
      store,
      recordStore,
      eventListeners,
      handshakes,
      controllerStore,
      datasetId,
      datasetType,
      isFixedItem,
      siblingDynamicPageUrlGetter,
      dependenciesManager,
      onIdle,
      getConnectedComponentIds,
      dispatcher,
    })

    const uniqueRoles = uniq(connections.map(conn => conn.role))
    const appDatasetApi = datasetApi(false)
    const componentAdapterContexts = []

    const adapterParams = {
      getState: store.getState,
      datasetApi: appDatasetApi,
      eventListeners,
      dispatcher,
      roles: uniqueRoles,
      getFieldType,
      getSchema,
      controllerFactory,
      controllerStore,
      PresetVerboseMessage: VerboseMessage.with({
        collectionId: datasetCollectionName,
        parentId,
      }),
      parentId,
      modeIsLivePreview,
      wixFormatter:
        (modeIsSSR && renderer === 'bolt') || !locale
          ? null
          : wixFormattingCreator({
              locale,
            }),
    }
    const adapterApi = adapterApiCreator({
      dispatch: store.dispatch,
      recordStore,
      componentAdapterContexts,
    })

    unsubscribeHandlers.push(
      recordStoreService
        .map(service =>
          service.onChange(
            onChangeHandler(store.getState, store.dispatch, adapterApi),
          ),
        )
        .getOrElse(() => {}),
    )

    const setCurrentRecord = maybeRecord =>
      maybeRecord.map(record =>
        store.dispatch(recordActions.setCurrentRecord(record, 0)),
      )

    const {
      fetchingInitialData,
      resolveUserInputDependency,
      resolveControllerDependencies,
    } = fetchData({
      shouldFetchInitialData: controllerConfig && !datasetIsWriteOnly,
      recordStore,
      store,
      filter,
      datasetIsDeferred,
      modeIsSSR,
    })

    fetchingInitialData
      .then(() => {
        markDatasetDataFetched()
        recordStore().fold(
          () => null,
          service => {
            setCurrentRecord(getFirstRecord(service))
          },
        )
      })
      .then(() =>
        datasetIsDeferred
          ? renderingRegularControllers
          : Promise.resolve(Maybe.Nothing()),
      )

    handshakes.forEach(handshake =>
      performHandshake(dependenciesManager, store.dispatch, handshake),
    )

    const shouldRefreshDataset = () => {
      const currentRecordIndex = selectCurrentRecordIndex(store.getState())
      const isPristine = recordStore().fold(
        () => false,
        service => service.isPristine(currentRecordIndex),
      )

      return isPristine && !datasetIsWriteOnly
    }

    const pageReady = async function () {
      user.onLogin(() => {
        // THIS SHOULD HAPPEN SYNCHRONOUSLY SO TESTS WILL REMAIN MEANINGFUL
        // IF YOU EVER FIND THE NEED TO MAKE IT ASYNC - TALK TO leeor@wix.com
        if (shouldRefreshDataset()) {
          appDatasetApi.refresh()
        }
      })

      setConnectedComponents(
        getComponentsToUpdate({
          connectedComponents: findConnectedComponents(uniqueRoles, $w),
          updatedCompIds,
          datasetIsReal,
        }),
      )

      // THIS SHOULD HAPPEN SYNCHRONOUSLY AFTER PAGE READY IS CALLED TO KEEP CONTROLLERS RUNNING SEQUENCE
      const detailsControllersToHandshake = resolveHandshakes({
        datasetApi: appDatasetApi,
        components: getConnectedComponents(),
        controllerConfig,
        controllerConfigured: isDatasetConfigured(store.getState()),
      })
      detailsControllersToHandshake.forEach(({ controller, handshakeInfo }) =>
        controller.handshake(handshakeInfo),
      )

      if (hasDatabindingDependencies(filter)) {
        await resolveControllerDependencies()
      }

      const dependencies = dependenciesManager.get()

      // scoped datasets are sure to have the schema resolved and therefore don't have to wait
      if (datasetIsReal) {
        await schemasLoading
      }

      resolveUserInputDependency()

      // removed collection, nothing to bind.
      if (!dataProvider.hasSchema(controllerConfig.dataset.collectionName)) {
        // yes, this is nonsense. but this is how our product works now.
        // even if collection is removed, so there is imposible to bind,
        // datasetApi still works! it returns emty data,
        // but works and there can be users out there, relying on this shitty behaviour.
        // TODO: so we need to investigate and get product decision for this case... Triascia!
        fetchingInitialData.then(() => {
          markControllerAsRendered()
          store.dispatch(configActions.setIsDatasetReady(true))
          fireEvent('datasetReady')
        })

        return Promise.resolve()
      }

      componentAdapterContexts.push(
        ...createComponentAdapterContexts({
          connectedComponents: getConnectedComponents(),
          $w,
          adapterApi,
          getFieldType,
          ignoreItemsInRepeater: datasetIsReal,
          dependencies,
          adapterParams,
        }),
      )

      if (datasetIsReal) {
        //TODO: add additional check by master dataset
        const detailsRepeatersAdapterContexts =
          createDetailsRepeatersAdapterContexts(
            getConnectedComponents(),
            getFieldType,
            dependencies,
            adapterParams,
          )
        componentAdapterContexts.push(...detailsRepeatersAdapterContexts)
      }

      subscribe(
        rootSubscriber(
          recordStore,
          adapterApi,
          getFieldType,
          eventListeners.executeHooks,
          datasetId,
          componentAdapterContexts,
          fireEvent,
          dispatcher,
        ),
      )

      unsubscribeHandlers.push(
        syncComponentsWithState(
          store,
          componentAdapterContexts,
          logger,
          datasetId,
          recordStore,
        ),
      )

      const defaultRecord = generateRecordFromDefaultComponentValues(
        componentAdapterContexts.filter(
          ({ role }) =>
            ![UPLOAD_BUTTON_ROLE, SIGNATURE_INPUT_ROLE].includes(role),
        ),
      )

      store.dispatch(recordActions.setDefaultRecord(defaultRecord))
      if (isDatasetConfigured(store.getState()) && datasetIsWriteOnly) {
        await store.dispatch(recordActions.initWriteOnly(datasetIsVirtual))
      }

      if (datasetIsDeferred) {
        // we should hide all components connected to deferred dataset before telling the Platform we are ready
        adapterApi().hideComponent({ rememberInitiallyHidden: true })

        if (modeIsSSR) adapterApi().clearComponent()
      }

      const pageReadyResult = fetchingInitialData.then(async () => {
        if (!modeIsSSR) {
          try {
            reportDatasetActiveOnPage(
              store.getState(),
              connections,
              datasetType,
              datasetIsVirtual,
              datasetId,
            )
          } catch (err) {
            logger.log(
              new AppError('Failed to report dataset active BI', {
                cause: err,
              }),
            )
          }
        }
        await initAdapters(adapterApi())
        if (datasetIsReal) {
          await waitForAllChildControllersToBeReady(controllerStore)
        }
        if (datasetIsDeferred) {
          // we should show all components connected to deferred dataset only after all child controllers (repeater items) are ready
          adapterApi().showComponent({ ignoreInitiallyHidden: true })
        }
        store.dispatch(configActions.setIsDatasetReady(true))
        fireEvent('datasetReady')
      })

      if (datasetIsDeferred) {
        markControllerAsRendered()

        return Promise.resolve()
      } else {
        pageReadyResult.then(markControllerAsRendered)

        return pageReadyResult
      }
    }

    const userCodeDatasetApi = datasetApi(true)
    const dynamicExports = (scope /*, $w*/) => {
      switch (scope.type) {
        case SCOPE_TYPES.COMPONENT:
          return userCodeDatasetApi.inScope(
            scope.compId,
            scope.additionalData.itemId,
          )
        default:
          return userCodeDatasetApi
      }
    }

    const dispose = () => {
      componentAdapterContexts.splice(0)
      unsubscribeHandlers.forEach(h => h())
    }

    const finalPageReady = datasetIsVirtual
      ? pageReady
      : () => logger.log(new Trace('dataset/pageReady', pageReady))

    return {
      pageReady: errorHandling(finalPageReady, e =>
        logger.logError(e, 'Dataset pageReady callback failed', { datasetId }),
      ),
      exports: dynamicExports,
      staticExports: userCodeDatasetApi,
      dispose,
    }
  }

export default createDataset
