import {
  DataBindingError,
  AppError,
  UserError,
  UnhandledPromiseRejection,
} from './errors'
import { Bi, BiError, VerboseMessage } from './events'
import { Thenable, ExtendedMap, errorHandling } from '../helpers'
import { DEPRECATED_METHODS_MAP } from '../helpers/constants'
import appContext from '../viewer-app-module/DataBindingAppContext'

export * from './errors'
export * from './events'
export * from './bi'

export class DataBindingLogger {
  constructor(logger, global) {
    this.#logger = logger
    this.#listenToUnhandledErrors(global)
  }

  log(event) {
    if (this.#shouldLogErrorBi(event)) {
      this.#logger.log(event, new BiError(event.cause || event))
    }

    if (this.#shouldLog(event)) {
      return this.#logger.log(event)
    }
  }

  logError(e, messageForUnhandled, optsForUnhandled) {
    this.log(
      e instanceof DataBindingError
        ? e
        : new AppError(messageForUnhandled, {
            cause: e,
            ...optsForUnhandled,
          }),
    )
  }

  #logger
  #predicateByEventType = new ExtendedMap([
    [Bi, ({ mode, env }) => mode.csr && !env.dev],
    [VerboseMessage, ({ mode, env }) => mode.verbose && env.preview],
  ])

  #shouldLog(event) {
    const shouldLogPredicate =
      this.#predicateByEventType.find((_, Type) => event instanceof Type) ||
      (() => true)

    return shouldLogPredicate(appContext.platform.settings)
  }

  #shouldLogErrorBi(event) {
    return (
      event instanceof DataBindingError &&
      !(event instanceof UserError) &&
      this.#predicateByEventType.get(Bi)(appContext.platform.settings)
    )
  }

  #listenToUnhandledErrors(global) {
    global.addEventListener('unhandledrejection', e => {
      if (e.reason instanceof DataBindingError) {
        e.preventDefault()
        this.#logger.log(new UnhandledPromiseRejection({ cause: e.reason }))
      }
    })
  }
}

export const createErrorReporting = logger => (fn, PresetError) =>
  errorHandling(fn, e => logger.log(new PresetError({ cause: e })))

export const createBreadcrumbReporting = logger => (fn, PresetBreadcrumb) =>
  errorHandling(
    (...args) => {
      const result = fn(...args)

      logger.log(new PresetBreadcrumb({ data: { args, result } }))

      return result
    },
    e => {
      logger.log(
        new PresetBreadcrumb({ data: { exception: e }, level: 'error' }),
      )
      throw e
    },
  )

export const createVerboseReporting =
  logger =>
  (fn, methodName) =>
  (...args) => {
    if (methodName.startsWith('on')) {
      logger.log(
        new VerboseMessage(VerboseMessage.types.DS_API.REGISTERED, {
          methodName,
        }),
      )

      return fn(...args)
    }

    logger.log(
      new VerboseMessage(VerboseMessage.types.DS_API.CALLED, {
        methodName,
        args,
      }),
    )

    if (DEPRECATED_METHODS_MAP.has(methodName)) {
      const replacementMethodName = DEPRECATED_METHODS_MAP.get(methodName)

      logger.log(
        new VerboseMessage(VerboseMessage.types.DS_API.DEPRECATED, {
          methodName,
          replacementMethodName,
        }),
      )
    }

    return new Thenable(fn(...args)).then(result => {
      logger.log(
        new VerboseMessage(VerboseMessage.types.DS_API.SUCCED, {
          methodName,
          result,
        }),
      )

      return result
    })
  }
