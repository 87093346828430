import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { createFormEventPayload } from './helpers/automationUtils'

export const createListenersByEvent = ({ httpClient, env }) => {
  const automationsClient = viewerAutomationsClientCreator({ httpClient })
  const reportFormEventToAutomation =
    automationsClient.reportFormEventToAutomationCreator({
      isPreview: env.editor,
    })

  return {
    afterSave: async (
      { datasetId, getSchema, getState },
      prevRecord,
      nextRecord,
    ) => {
      const state = getState()
      const schema = getSchema()
      const isForm = Boolean(state.records.isForm)

      if (!isForm || !schema) return

      await reportFormEventToAutomation(
        createFormEventPayload({
          datasetId,
          record: nextRecord,
          schema,
          getSchema,
        }),
      )
    },
  }
}
